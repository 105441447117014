import CryptoJS from "crypto-js";
import React, { Component } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, withRouter } from "react-router-dom";
import logo from "../../../assets/images/prokraya-logo-white.png";
import logosm from "../../../assets/images/prokraya-sm-new.png";
import Icon from "../../../components/icon";
import { CircularBar } from "../../../components/progressbar";
import { env, env_url } from "../../../environments/env";
import HttpServices from "../../../environments/environment_urls";
import { applicationTransmissionFlow, getCsrfToken } from "../../../functions/common";
import {
  excludeSupplierRoutes,
  getURLFromURL,
  setActiveMenu,
} from "../../../functions/url-modifications";
var sessionTimer;
class Leftsidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewId: "",
      supplierStatus: "",
      companyStatus: false,
      supplierMenuList: [
        {
          id: 1,
          iconName: "sd_dashboard",
          menuName: "Dashboard",
          url: "/dashboard",
        },
        {
          id: 2,
          iconName: "sd_suppliers",
          menuName: "Registration Profile",
          url: "/supplier-approval",
          subUrls: ["/supplier-approval"],
        },
        {
          id: 3,
          iconName: "sd_sourcing",
          menuName: "Purchase Orders",
          url: "/po-landing",
          subUrls: ["/po-preview"],
        },
        {
          id: 4,
          iconName: "sd_invoices",
          menuName: "Invoices",
          url: "/invoices",
          subUrls: ["/create-invoice", "/advanced-invoice", "/invoice-preview"],
        },
      ],
      collapsed: false,
      menuList: [],
      approvalStatus: false,
      openedState: "",
      toggled: false,
    };
  }

  componentDidMount() {
    this.setState({ supplierStatus: sessionStorage.getItem("supplierStatus") });
    const token = sessionStorage.getItem("token");
    const userName = sessionStorage.getItem("userName");
    console.log(token, userName);
    if (token !== "InValid User") {
      if (
        excludeSupplierRoutes(window.location.pathname?.replace("/", "")) ===
        false
      ) {
        const authForm = new FormData();
        authForm.append(
          "functionUrl",
          getURLFromURL(window.location.pathname?.replace("/", ""))
        );
        HttpServices.postData(env_url.getRoleAuthorize, authForm)
          .then((resp) => {
            if (
              resp.data === false &&
              window.location.pathname !== "/forbidden"
            ) {
              window.location.replace("/forbidden");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      sessionTimer =setInterval(()=>{
        const date=sessionStorage.getItem("lastCallTime");
        const compareDate=new Date(date);
        const now = new Date();
        const difference = now.getTime()-compareDate.getTime();
        const token=sessionStorage.getItem("token");
        const url=env.baseUrl+env_url.validatetoken;
        if (difference <= 1670000) {
          fetch(url, {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              "X-CSRF-TOKEN": getCsrfToken(),
              "X-XSRF-TOKEN": getCsrfToken(),
            },
          })
            .then((result) => result.json())
            .then((res) => {
              if(res?.access_token){
                sessionStorage.setItem("token",res.access_token)
                sessionStorage.setItem("lastCallTime",new Date())
              }
            });
        }
      },1700000)
      const form = new FormData();
      form.append("userName", userName);
      const bytes = CryptoJS.AES.decrypt(
        userName,
        applicationTransmissionFlow()
      );
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      HttpServices.postData(env_url.loadUser, form)
        .then((resp) => {
          const userDetails = resp.data;
          env.userInfo = { ...userDetails, username: decryptedData };
          if (resp.data) {
            this.getAllMenues(userDetails);
            sessionStorage.setItem("lastLogin", userDetails.lastLoginDate);
          }
          sessionStorage.setItem("suppId", resp.data.businessEntity.attribute1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.getSupplierUserAccount();
  }

  componentWillUnmount(){
    if (sessionTimer != undefined) clearInterval(sessionTimer);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      return this.getActiveMenu();
    }
  }

  getSupplierUserAccount = () => {
    this.setState({ loader: true });
    const url = env_url.getCompanyDetails;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        let suppUserObj = response.data.supplierObj;
        if (suppUserObj.status !== null) {
          this.setState({ companyStatus: true });
        }
        if (suppUserObj.status === "Pending Approval") {
          this.setState({ approvalStatus: true });
        }
        const menuList = this.state.supplierMenuList.map((item) => {
          if (2 === item.id) return { ...item, url: item.url + suppUserObj.id };
          return { ...item };
        });
        this.setState({
          supplierMenuList: menuList,
          previewId: suppUserObj.id,
          loader: false,
        });
      }
    });
  };

  toggleSidebar = () => {
    document
      .querySelector(".content-block")
      .classList.toggle("collapsedSidebar");
  };

  getAllMenues = (userDetails) => {
    let menuList = [];
    for (var key in userDetails.menus) {
      const subMenuList = userDetails.menus[key]
        .filter((element, index) => {
          if (index >= 0) {
            return element;
          }
        })
        .map((menu) => {
          return {
            id: menu.id,
            name: menu.description,
            url: "/" + menu.functionUrl,
            activeSubUrl: false,
          };
        });
      if (userDetails.menus[key].length > 1) {
        menuList.push({
          id: userDetails.menus[key][0].id,
          iconName: userDetails.menus[key][0].iconName,
          menuName: userDetails.menus[key][0].moduleName,
          url: "/" + userDetails.menus[key][0].functionUrl,
          activeUrl: false,
          subUrls: subMenuList,
        });
      } else {
        menuList.push({
          id: userDetails.menus[key][0].id,
          iconName: userDetails.menus[key][0].iconName,
          menuName: userDetails.menus[key][0].moduleName,
          url: "/" + userDetails.menus[key][0].functionUrl,
          activeUrl: false,
        });
      }
    }
    this.setState({ menuList }, () => this.getActiveMenu());
  };

  getActiveMenu = () => {
    let menuList = this.state.menuList;
    let mainmenu = window.location.pathname;
    let openState = "";
    if (menuList) {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        element.activeUrl = false;
        if (element.subUrls && element.subUrls?.length > 0) {
          const suburl = element.subUrls?.map((item) => {
            item.activeSubUrl = false;
            return item;
          });
          element.subUrls = suburl;
        }
        if (!element.subUrls && element.url === mainmenu) {
          element.activeUrl = true;
        } else if (element.subUrls && element.subUrls?.length > 0) {
          if (element.subUrls?.some((item) => item.url === mainmenu)) {
            element.activeUrl = true;
            openState = element.id;
            const suburl = element.subUrls?.map((item) => {
              if (item.url?.replace("/", "") === mainmenu?.replace("/", "")) {
                item.activeSubUrl = true;
                return item;
              } else {
                item.activeSubUrl = false;
                return item;
              }
            });
            element.subUrls = suburl;
          }
        } else {
          if (
            element.url?.replace("/", "") ===
            setActiveMenu(mainmenu?.replace("/", ""))
          ) {
            element.activeUrl = true;
            openState = element.id;
            if (element.subUrls && element.subUrls?.length > 0) {
              const suburl = element.subUrls?.map((item) => {
                if (
                  item.url?.replace("/", "") ===
                  setActiveMenu(mainmenu?.replace("/", ""))
                ) {
                  item.activeSubUrl = true;
                  return item;
                } else {
                  item.activeSubUrl = false;
                  return item;
                }
              });
              element.subUrls = suburl;
            }
          }
        }
      }
    }
    this.setState({ menuList, openedState: openState });
  };

  menuIconClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.toggleSidebar();
  };

  getActiveMenuItem = (menuItem) => {
    sessionStorage.removeItem("AuctionActiveTab");
    sessionStorage.removeItem("pageNumber");
    sessionStorage.removeItem("pageSize");
    this.props.handleToggleSidebar(false);
    let menuList = this.state.menuList;
    let openState = "";
    if (menuList && menuItem) {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        element.activeUrl = false;
        if (element.subUrls && element.subUrls?.length > 0) {
          const suburl = element.subUrls?.map((item) => {
            item.activeSubUrl = false;
            return item;
          });
          element.subUrls = suburl;
        }
        if (!element.subUrls && element.url === menuItem.url) {
          element.activeUrl = true;
        } else if (element.subUrls && element.subUrls?.length > 0) {
          if (element.subUrls?.some((item) => item.url === menuItem.url)) {
            element.activeUrl = true;
            openState = element.id;
            const suburl = element.subUrls?.map((item) => {
              if (
                item.url?.replace("/", "") === menuItem.url?.replace("/", "")
              ) {
                item.activeSubUrl = true;
                return item;
              } else {
                item.activeSubUrl = false;
                return item;
              }
            });
            element.subUrls = suburl;
          }
        } else {
          if (
            element.url?.replace("/", "") ===
            setActiveMenu(menuItem.url?.replace("/", ""))
          ) {
            element.activeUrl = true;
            openState = element.id;
            if (element.subUrls && element.subUrls?.length > 0) {
              const suburl = element.subUrls?.map((item) => {
                if (
                  item.url?.replace("/", "") ===
                  setActiveMenu(menuItem.url?.replace("/", ""))
                ) {
                  item.activeSubUrl = true;
                  return item;
                } else {
                  item.activeSubUrl = false;
                  return item;
                }
              });
              element.subUrls = suburl;
            }
          }
        }
      }
    }
    this.setState({ menuList, openedState: openState });
  };

  getSubActiveMenu = (menuItem, subMenu) => {
    this.props.handleToggleSidebar(false);
    let menuList = this.state.menuList;
    let openState = "";
    if (menuList && menuItem) {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        element.activeUrl = false;
        if (element.subUrls && element.subUrls?.length > 0) {
          const suburl = element.subUrls?.map((item) => {
            item.activeSubUrl = false;
            return item;
          });
          element.subUrls = suburl;
        }
        if (!element.subUrls && element.url === menuItem.url) {
          element.activeUrl = true;
        } else if (element.subUrls && element.subUrls?.length > 0) {
          if (element.subUrls?.some((item) => item.url === menuItem.url)) {
            element.activeUrl = true;
            openState = element.id;
            const suburl = element.subUrls?.map((item) => {
              if (item.url?.replace("/", "") === subMenu?.replace("/", "")) {
                item.activeSubUrl = true;
                return item;
              } else {
                item.activeSubUrl = false;
                return item;
              }
            });
            element.subUrls = suburl;
          }
        } else {
          if (
            element.url?.replace("/", "") ===
            setActiveMenu(menuItem.url?.replace("/", ""))
          ) {
            element.activeUrl = true;
            openState = element.id;
            if (element.subUrls && element.subUrls?.length > 0) {
              const suburl = element.subUrls?.map((item) => {
                if (
                  item.url?.replace("/", "") ===
                  setActiveMenu(subMenu?.replace("/", ""))
                ) {
                  item.activeSubUrl = true;
                  return item;
                } else {
                  item.activeSubUrl = false;
                  return item;
                }
              });
              element.subUrls = suburl;
            }
          }
        }
      }
    }
    this.setState({ menuList, openedState: openState });
  };

  onOpenChange = (menuItem) => {
    let count = 0;
    let menuList = this.state.menuList;
    if (menuItem.id === this.state.openedState) {
      this.setState({ openedState: "" });
    } else {
      for (let i = 0; i < menuList.length; i++) {
        const element = menuList[i];
        if (element.id === menuItem.id) {
          if (count % 2 === 0) {
            this.setState({ openedState: menuItem.id });
          } else {
            this.setState({ openedState: "" });
          }
        }
      }
    }
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <React.Fragment>
        {(props.supplier || state.supplierStatus === "Pending") && (
          <React.Fragment>
            <ProSidebar
              collapsed={state.collapsed}
              breakPoint="md"
              toggled={props.toggled}
              onToggle={props.handleToggleSidebar}
            >
              <SidebarHeader>
                <div className="block-logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </SidebarHeader>
              <SidebarContent>
                <Menu iconShape="square">
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCompanyDetails ? "complete" : "") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="company-details" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/company-details"}
                  >
                    <Link
                      to="/company-details"
                      className={
                        "menu-list-name " +
                        (props.completedCompanyDetails ? "complete" : "") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Company Details</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedContactReference ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="contact-reference" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/contact-details"}
                  >
                    <Link
                      to="/contact-details"
                      className={
                        "menu-list-name " +
                        (props.completedContactReference ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Contacts</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedScopeofSupply ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="scope-of-supply" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/scope-of-supply"}
                  >
                    <Link
                      to="/scope-of-supply"
                      className={
                        "menu-list-name " +
                        (props.completedScopeofSupply ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Scope of Supply</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedBanking ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="banking" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/banking"}
                  >
                    <Link
                      to="/banking"
                      className={
                        "menu-list-name " +
                        (props.completedBanking ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Banking</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCertificates ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu") +
                      (state.approvalStatus ? " disableMenu" : "")
                    }
                    icon={<Icon iconName="certificates" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/certificates"}
                  >
                    <Link
                      to="/certificates"
                      className={
                        "menu-list-name " +
                        (props.completedCertificates ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu") +
                        (state.approvalStatus ? " disableMenu" : "")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Certificates</span>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className={
                      "menu-list-name " +
                      (props.completedCertificates ? "complete" : "") +
                      (state.companyStatus ? "" : "disableMenu")
                    }
                    icon={<Icon iconName="administration" iconSize={22} />}
                    style={{
                      pointerEvents: props.disableBlock ? "none" : "auto",
                      opacity: props.disableBlock ? 0.5 : 1,
                    }}
                    active={window.location.pathname === "/supplier-preview"}
                  >
                    <Link
                      to={{
                        pathname: "/supplier-preview",
                        state: { id: this.state.previewId },
                      }}
                      className={
                        "menu-list-name " +
                        (props.completedCertificates ? "complete" : "") +
                        (state.companyStatus ? "" : "disableMenu")
                      }
                      activeClassName="activeMenu"
                    >
                      <span>Review Profile</span>
                    </Link>
                  </MenuItem>
                  <CircularBar
                    className="mt-5"
                    completed={
                      props.completedProgress ? props.completedProgress : 0
                    }
                    addition={"%"}
                    total={100}
                    LabelVal={
                      props.completedProgress ? props.completedProgress : 0
                    }
                    showlabel
                    label="Profile Completion"
                  />
                </Menu>
              </SidebarContent>
              <SidebarFooter />
            </ProSidebar>
          </React.Fragment>
        )}

        {props.showMenu &&
          !props.supplier &&
          state.supplierStatus !== "Pending" && (
            <ProSidebar
              collapsed={state.collapsed}
              breakPoint="md"
              toggled={props.toggled}
              onToggle={props.handleToggleSidebar}
            >
              <SidebarHeader>
                <div className="block-logo">
                  <Link to="/">
                    <img src={state.collapsed ? logosm : logo} alt="logo" />
                  </Link>
                </div>
                <div className="toggle-block" onClick={this.menuIconClick} />
              </SidebarHeader>
              <SidebarContent>
                {state.menuList?.map((menuitem) => (
                  <Menu iconShape="square" key={menuitem.id}>
                    {menuitem.subUrls ? (
                      <SubMenu
                        open={state.openedState === menuitem.id ? true : false}
                        onOpenChange={() => this.onOpenChange(menuitem)}
                        title={menuitem.menuName}
                        className={menuitem.activeUrl ? "active" : ""}
                        icon={
                          <Icon iconName={menuitem.iconName} iconSize={22} />
                        }
                        style={{
                          pointerEvents: props.disableBlock ? "none" : "auto",
                          opacity: props.disableBlock ? 0.5 : 1,
                        }}
                      >
                        {menuitem.subUrls?.map((submenu) => (
                          <MenuItem
                            className={submenu.activeSubUrl ? "active" : ""}
                            key={submenu.id}
                          >
                            <Link
                              to={submenu.url}
                              onClick={() =>
                                this.getSubActiveMenu(menuitem, submenu.url)
                              }
                            >
                              {submenu.name}
                            </Link>
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        className={menuitem.activeUrl ? "active" : ""}
                        icon={
                          <Icon iconName={menuitem.iconName} iconSize={22} />
                        }
                        style={{
                          pointerEvents: props.disableBlock ? "none" : "auto",
                          opacity: props.disableBlock ? 0.5 : 1,
                        }}
                      >
                        <Link
                          to={menuitem.url}
                          onClick={() => this.getActiveMenuItem(menuitem)}
                        >
                          {menuitem.menuName}
                        </Link>
                      </MenuItem>
                    )}
                  </Menu>
                ))}
              </SidebarContent>
              <SidebarFooter />
            </ProSidebar>
          )}
      </React.Fragment>
    );
  }
}

export default withRouter(Leftsidebar);
